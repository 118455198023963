<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl font-normal mb-5">
      Whatsapp Template
      <img src="@/assets/WhatsApp.png" class="inline h-9 ml-2" />
    </h1>
    <div>
      <h2 class="text-2xl my-5">
        Criar template personalizado
        <span class="text-gray-400 font-normal text-sm_3">(campos com <b class="text-red-500 font-normal">*</b> são obrigatórios)</span>
      </h2>
      <form @submit.prevent="cadastrar()">
        <div class="grid grid-cols-12">
          <div class="col-span-12 md:col-span-8">
            <div class="grid grid-cols-12">
              <Card>
                <label for="nome" class="text-zinc-700 font-semibold mb-5">Nome<b class="text-red-500 font-normal">*</b></label>
                <p class="text-gray-400 text-sm my-3">Dê um nome para seu modelo de mensagem.</p>

                <input
                  v-model="template.nome"
                  :required="true"
                  type="text"
                  name="nome"
                  @input="ajustaNomeTemplate()"
                  placeholder="Insira o nome do template de mensagem..."
                  class="mt-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
                <p class="text-gray-400 text-sm_2 mt-5">O nome deve conter apenas letras minúsculas, sem caracteres especiais e separado por underlines/underscore( _ )</p>
              </Card>
              <Card>
                <label for="nome" class="text-zinc-700 font-semibold mb-5">Categoria e número<b class="text-red-500 font-normal">*</b></label>
                <p class="text-gray-400 text-sm my-3">Escolha um número (previamente cadastrado) para registrar o template.</p>
                <v-select
                  v-model="template.numero"
                  :required="true"
                  :options="numeros"
                  label="telefone"
                  placeholder="Selecione o número a ser vinculado ao template"
                  :reduce="(p) => p._id"
                  name="telefone"
                  id="telefone"
                  class="placeholder-gray-400 mt-4 border-gray-300 rounded-md sm-select"
                />

                <p class="text-gray-400 text-sm my-3">Escolhe a categoria do template a ser criado.</p>
                <v-select
                  v-model="template.categoria"
                  :required="true"
                  :options="opcoesCategoria"
                  placeholder="Selecione a categoria do template"
                  name="categoria"
                  id="categoria"
                  class="placeholder-gray-400 mt-4 border-gray-300 rounded-md sm-select"
                />
              </Card>
              <Card class="card-scroll">
                <label for="cabecalho" class="text-zinc-700 font-semibold mb-4">Cabeçalho <span class="bg-blue-100 p-1 text-gray-400 text-sm rounded-md">(opcional)</span></label>
                <p class="text-gray-400 text-sm mt-2">Topo da mensagem enviada, cabeçalho inicial.</p>
                <v-select v-model="template.header.format" v-if="template.categoria !== 'AUTHENTICATION'" :options="opcoesCabecalho" placeholder="Nenhum" class="sm-select w-full my-5 md:w-4/5 xl:1/3" />
                <p v-else class="mt-10 text-gray-400 font-semibold text-lg">Não é possível adicionar cabeçalho em um template de Autorização.</p>
                <p v-if="template.header.format !== null" class="text-gray-400 text-sm mt-2">Adicione um título ou escolha qual tipo de mídia você usará para esse cabeçalho.</p>
                <div v-if="template.header.format !== null && template.header.format === 'TEXT'">
                  <input
                    v-model="template.header.text"
                    type="text"
                    name="cabecalho"
                    placeholder="Insira o texto do cabeçalho..."
                    @input="somaNoContador('contadorHeader', template.header.text)"
                    class="my-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    maxlength="60"
                  />
                  <div class="relative">
                    <span class="text-gray-400 text-sm_2 absolute right-1">{{ contadorHeader }}/60</span>
                  </div>
                </div>
                <div v-else-if="template.header.format !== null && template.header.format !== 'TEXT'">
                  <vue-dropzone
                    required
                    :key="keyDropZone"
                    ref="arquivo"
                    id="arquivo"
                    class="w-full"
                    @vdropzone-sending="send"
                    :options="dropzoneAttachmenOptions"
                    v-on:vdropzone-success="updateArquivo"
                    v-on:vdropzone-removed-file="removeArquivo"
                  >
                  </vue-dropzone>
                </div>
              </Card>
              <Card class="card-scroll">
                <label for="nome" class="text-zinc-700 font-semibold">Corpo <b class="text-red-500 font-normal">*</b></label>
                <div class="flex justify-between mt-2">
                  <p class="text-gray-400 text-sm mb-2">Insira o texto da sua mensagem.</p>
                  <span class="text-green-600 font-semibold text-sm cursor-pointer" @click="adicionarVariavel()" v-tooltip="mensagemAddVariaveis">{ adicionar variável }</span>
                </div>
                <textarea
                  rows="3"
                  v-model="template.body.text"
                  type="text"
                  @input="auto_grow"
                  placeholder="Insira o texto aqui..."
                  id="sendtextarea"
                  maxlength="1024"
                  ref="textInput"
                  class="my-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  :required="true"
                  :disabled="template.categoria === 'AUTHENTICATION'"
                  style="resize: none"
                ></textarea>
                <label class="font-normal text-sm text-gray-400" v-for="(position, index) in template.body.placeholders" :key="index">
                  Usado apenas para a validação do WhatsApp, variáveis podem ser modificadas no momento do envio. <span class="text-red-500 font-semibold">*</span>
                  <input
                    :placeholder="`Insira o texto de exemplo da variável {{${position}}}`"
                    class="my-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full text-black shadow-sm sm:text-sm border-gray-300 rounded-md"
                    maxlength="60"
                    v-model="template.body.examples[index]"
                    :disabled="template.categoria === 'AUTHENTICATION'"
                    type="text"
                  />
                </label>
                <div class="relative">
                  <span class="text-gray-400 text-sm_2 absolute right-1">{{ contadorBody }}/1024</span>
                </div>
              </Card>
              <Card class="card-scroll">
                <p class="text-zinc-700 font-semibold mb-3">
                  Rodapé
                  <span class="bg-blue-100 p-1 text-gray-400 text-sm rounded-md">(opcional)</span>
                </p>
                <label for="footer" class="text-gray-400 text-sm my-2">
                  Adicione uma pequena linha de texto na parte inferior do seu modelo de mensagem. Se você adicionar o botão para recusar o marketing, o rodapé associado será exibido aqui por padrão.
                </label>
                <input
                  v-model="template.footer.text"
                  type="text"
                  name="footer"
                  placeholder="Insira o texto do rodapé..."
                  @input="somaNoContador('contadorFooter', template.footer.text)"
                  :disabled="desativarRodape"
                  class="my-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  maxlength="60"
                />
                <div class="relative" :class="{ 'mb-8': template.categoria === 'AUTHENTICATION' }">
                  <span class="text-gray-400 text-sm absolute right-1">{{ contadorFooter }}/60</span>
                </div>
                <div v-if="template.categoria === 'AUTHENTICATION'">
                  <input
                    v-model="template.footer.codeExpirationMinutes"
                    type="number"
                    name="footer"
                    class="my-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    max="60"
                    min="0"
                  />
                </div>
              </Card>
              <Card class="card-scroll">
                <p class="text-zinc-700 font-semibold mb-3">
                  Botões
                  <span class="bg-blue-100 p-1 text-gray-400 text-sm rounded-md">(opcional)</span>
                </p>
                <label for="botoes" class="text-gray-400 text-sm mb-2"> Crie botões para os clientes responderem à sua mensagem. </label>
                <v-select v-if="this.template.categoria !== 'AUTHENTICATION'" v-model="button.format" :options="opcoesBotoes" placeholder="+ Adicione um botão" class="sm-select w-full xl:w-1/2 my-2" />
                <p v-else class="mt-2 text-gray-400 font-semibold text-lg">Não é possível adicionar botões em um template de Autorização.</p>
                <div v-if="button.format">
                  <div v-if="button.format === 'Personalizado'">
                    <input
                      v-model="button.text"
                      type="text"
                      name="textoBotaaoa "
                      @input="somaNoContador('contadorBotao', button.text)"
                      class="my-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      maxlength="25"
                    />
                    <div class="relative">
                      <span class="text-gray-400 text-sm absolute right-1">{{ contadorBotao }}/25</span>
                    </div>
                  </div>
                  <button
                    type="button"
                    @click="addBotaoNaLista()"
                    :disabled="this.template.categoria === 'AUTHENTICATION'"
                    :class="{ 'opacity-30': this.template.categoria === 'AUTHENTICATION' }"
                    class="mt-2 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-1/4 font-semibold text-center"
                  >
                    Adicionar
                  </button>
                </div>
                <p class="text-gray-400 text-sm my-2" v-if="template.buttons.length > 0">Botões: {{ template.buttons.length }}/3</p>
                <div class="flex items-center" v-for="(botao, index) in template.buttons" :key="index">
                  <button
                    type="button"
                    class="block justify-center mt-2 py-2 w-56 border border-transparent shadow-lg text-sm font-medium rounded-md text-blue-500 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    {{ botao.text }}
                  </button>
                  <svg @click="removerBotao(botao)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="text-red-800 w-6 h-6 ml-3 cursor-pointer">
                    <path
                      fill-rule="evenodd"
                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              </Card>
            </div>
          </div>
          <div class="col-span-12 md:col-span-4 md:relative">
            <Card class="md:absolute w-full">
              <Preview
                class="preview-scroll"
                @removerBotao="removerBotao"
                :image="template.header.example"
                :document="template.header.filename"
                :examples="template.body.examples"
                :edit="true"
                :nome="template.nome"
                :header="template.header.text"
                :body="template.body.text"
                :footer="template.footer.text"
                :buttons="template.buttons"
              />
            </Card>
          </div>
        </div>
        <div class="md:h-espaco"></div>
        <div class="text-center md:border-t-2 md:fixed md:bottom-0 w-full bg-white z-10 md:left-0 md:right-0">
          <p class="text-zinc-700 font-normal my-2">
            Após clicar em <b class="text-blue-600">enviar</b>, seu template será direcionado por nós até a equipe do <b class="text-green-700">WhatsApp</b>, equipe essa que avaliará o template, validando-o ou não.
          </p>
          <p class="text-gray-400 text-sm font-medium mt-2">Confira todas as informações antes de submeter o template.</p>
          <button
            type="submit"
            :disabled="checaFormsOk()"
            :class="checaFormsOk() ? 'opacity-30' : 'hover:bg-blue-700 active:bg-blue-600'"
            class="bg-blue-600 text-white text-center w-full md:w-1/4 xl:w-1/12 text-lg p-2 rounded-md mt-4 mb-2"
          >
            Enviar
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Breadcrumb from "../../components/Breadcrumbs.vue";
import Card from "./components/Card.vue";
import Preview from "./components/Preview.vue";
import vueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  components: {
    Breadcrumb,
    Card,
    Preview,
    vueDropzone
  },
  data() {
    return {
      breadcrumb: [
        { url: "/templates", value: "Templates Whatsapp" },
        { url: "/templates/form", value: "Novo / Editar" }
      ],
      keyDropZone: 0,
      contadorHeader: 0,
      contadorBody: 0,
      contadorFooter: 0,
      contadorBotao: 0,
      desativarRodape: false,
      template: {
        nome: "",
        header: { text: "", format: null, example: "", filename: "" },
        body: { text: "", format: "TEXT", placeholders: 0, examples: [] },
        footer: { text: "", format: "TEXT", codeExpirationMinutes: 0 },
        buttons: [],
        numero: null,
        categoria: "",
        id: null
      },
      dropzoneBody: {
        maxFiles: 1,
        addRemoveLinks: true,
        capture: true,
        dictDefaultMessage: "Clique ou arraste seu anexo aqui",
        dictRemoveFile: "Remover",
        acceptedFiles: ""
      },
      numeros: [],
      dropzoneAttachmenOptions: this.$http.getDropzoneConfig(`upload`, "post", { ...this.dropzoneBody }),
      button: { text: "", format: "" },
      route: "/templates",
      opcoesCabecalho: ["TEXT", "DOCUMENT", "IMAGE"],
      opcoesCategoria: ["UTILITY", "MARKETING", "AUTHENTICATION"],
      opcoesBotoes: ["Cancelar marketing", "Personalizado"],
      telaEdicao: false,
      mensagemAddVariaveis: "Os placeholders devem ser formatados corretamente e na ordem correta. Exemplo: {{1}}, {{2}}, {{3}}..."
    };
  },
  methods: {
    async cadastrar() {
      const metodo = this.telaEdicao ? "put" : "post";
      if (this.template.body.examples.length > 0 && !this.checaExamplesOk()) {
        return this.$vToastify.error("Confira as variáveis do template!");
      }

      const form = new FormData();
      form.append("nome", this.template.nome);
      form.append("header", JSON.stringify(this.template.header));
      form.append("body", JSON.stringify(this.template.body));
      form.append("footer", JSON.stringify(this.template.footer));
      form.append("buttons", JSON.stringify(this.template.buttons));
      form.append("numero", JSON.stringify(this.template.numero._id));
      form.append("categoria", JSON.stringify(this.template.categoria));
      form.append("id", this.template.id);

      const response = await this.$http[metodo](`/v1/${this.route}/1/`, this.template);

      if (!response.data.success) {
        return this.$vToastify.error(response.data.err);
      }

      const mensagem = `Sucesso ao ${this.telaEdicao ? "editar" : "cadastrar novo"} template!`;
      this.$vToastify.success(mensagem);

      return this.$router.push({ path: this.route });
    },

    async start(id) {
      const response = await this.$http.get(`/v1/${this.route}/1/${id}`);

      this.template.nome = response.data.nome;
      this.template.header.text = response.data.header.text;
      this.template.header.format = response.data.header.format;
      this.template.header.example = response.data.header.example;
      this.template.header.filename = response.data.header.filename;
      this.template.categoria = response.data.categoria;
      this.template.numero = response.data.numero.telefone;
      this.template.body.text = response.data.body.text;
      this.template.body.format = response.data.body.format;
      this.template.body.placeholders = response.data.body.placeholders;
      this.template.body.examples = response.data.body.examples;
      this.template.footer.text = response.data.footer.text;
      this.template.footer.format = response.data.footer.format;
      this.template.footer.codeExpirationMinutes = response.data.footer.codeExpirationMinutes;
      this.template.buttons = response.data.buttons;

      this.template.id = response.data._id;
    },

    buscaRegex() {
      const regex = /{{(\d+)}}/g;
      let match;
      let maxNumero = 0;

      while ((match = regex.exec(this.template.body.text)) !== null) {
        const numeroEncontrado = parseInt(match[1], 10);
        if (numeroEncontrado > maxNumero) {
          maxNumero = numeroEncontrado;
        }
      }

      return maxNumero;
    },

    adicionarVariavel() {
      this.template.body.placeholders++;

      const textarea = this.$refs.textInput;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;

      const textoAntes = this.template.body.text.substring(0, start);
      const textoDepois = this.template.body.text.substring(end, this.template.body.text.length);
      const variavel = `{{${this.template.body.placeholders}}}`;

      const texto = textoAntes + variavel + textoDepois;
      this.template.body.text = texto;
      this.contadorBody = this.template.body.text.length;
    },

    updateArquivo(file, response) {
      const arquivo = response.file;
      this.template.header.example = `${this.api}/upload?mimetype=${arquivo.mimetype}&filename=${arquivo.filename}&folder=arquivosWhats`;

      if (arquivo.mimetype !== "image/png" && arquivo.mimetype !== "image/jpeg") {
        this.template.header.filename = arquivo.originalname;
      }
    },

    removeArquivo() {
      this.template.header.example = "";
      this.template.header.filename = "";
    },

    send(file, xhr, formData) {
      formData.append("folder", "arquivosWhats");
    },

    checaFormsOk() {
      return (
        !this.template.numero ||
        !this.template.categoria ||
        !this.template.nome ||
        !this.template.body.text ||
        this.template.body.placeholders !== this.template.body.examples.length ||
        (this.template.body.placeholders > 0 && !this.checaExamplesOk()) ||
        (this.template.categoria === "AUTHENTICATION" && this.template.footer.codeExpirationMinutes === 0)
      );
    },

    checaExamplesOk() {
      return Array.isArray(this.template.body.examples) && this.template.body.examples.length > 0 && this.template.body.examples.every((str) => typeof str === "string" && str.trim() !== "");
    },

    ajustaNomeTemplate() {
      let nome = this.template.nome;

      if (nome) {
        nome = nome.replace(/ /g, "_");
        nome = nome.replace(/[^a-zA-Z_]/g, "");

        nome = nome.toLocaleLowerCase();
      }

      this.template.nome = nome;
    },

    somaNoContador(contador, vModel) {
      this[contador] = vModel.length;
    },

    auto_grow(input) {
      if ((input.data === null && input.inputType === "insertLineBreak") || input.data === " ") {
        if (this.template.body.text.trim().length === 0) {
          this.template.body.placeholders = 0;
          this.template.body.examples = [];
          this.template.body.text = "";
          return;
        }
      }

      this.contadorBody = this.template.body.text.length;
    },

    addBotaoNaLista() {
      if (this.template.buttons.length === 3) {
        return this.$vToastify.error("Limite máximo de botões adicionados: 3.");
      }

      if (this.button.format === "Cancelar marketing") {
        this.template.footer.text = "Não tem interesse? Toque em Não tenho interesse.";
        this.button.text = "Não tenho interesse.";
        this.somaNoContador("contadorFooter", this.template.footer.text);

        this.desativarRodape = true;
      }

      const botao = { format: "QUICK_REPLY", text: this.button.text };

      if (this.template.buttons.some((btn) => btn.text === botao.text)) {
        this.button.text = "";
        this.button.format = "";
        return this.$vToastify.error("Botão já adicionado.");
      }

      this.template.buttons.push(botao);
      this.button.text = "";
      this.button.format = "";
      this.contadorBotao = 0;
    },

    removerBotao(botao) {
      if (botao.text === "Não tenho interesse.") {
        this.template.footer.text = "";
        this.somaNoContador("contadorFooter", this.template.footer.text);

        this.desativarRodape = false;
      }

      this.template.buttons = this.template.buttons.filter((btn) => btn.text !== botao.text);
    },

    updateDropzoneSettings() {
      if (this.template.header.format === null) {
        this.template.header.text = "";
        this.dropzoneBody.acceptedFiles = "";
        this.removeArquivo();
      }

      if (this.template.header.format === "DOCUMENT") {
        this.dropzoneBody.acceptedFiles = ".pdf,.DOCX";
      }

      if (this.template.header.format === "IMAGE") {
        this.dropzoneBody.acceptedFiles = ".png,.jpg";
      }

      this.dropzoneAttachmenOptions = this.$http.getDropzoneConfig(`upload`, "post", { ...this.dropzoneBody });
      this.keyDropZone++;
    }
  },
  watch: {
    "template.header.format": {
      handler() {
        this.updateDropzoneSettings();
      },
      deep: true
    },
    "template.body.text": {
      handler() {
        const maxNumero = this.buscaRegex();
        this.template.body.placeholders = maxNumero;
      },
      deep: true
    },
    "template.categoria": {
      handler(valor) {
        if (valor === "AUTHENTICATION") {
          this.template.body.text = "Seu código de verificação é *{{1}}*.";
          this.template.footer.text = "Este código expira em {{1}} minutos.";
          this.desativarRodape = true;

          if (!this.template.buttons.some((btn) => btn.format === "COPY_CODE")) {
            this.template.body.examples.push("123456");
            this.template.buttons.push({ text: "Copiar código", format: "COPY_CODE" });
          }
        }

        if (valor !== "AUTHENTICATION" && this.template.buttons.some((btn) => btn.format === "COPY_CODE")) {
          this.template.buttons = this.template.buttons.filter((btn) => btn.format !== "COPY_CODE");
          this.template.body.text = "";
          this.template.footer.text = "";
          this.template.body.examples = [];
          this.desativarRodape = false;
        }
      },
      deep: true
    },
    "template.footer.codeExpirationMinutes": {
      handler(valor) {
        if (this.template.categoria === "AUTHENTICATION") {
          if (valor !== 0) {
            this.template.footer.text = `Este código expira em ${valor} minutos.`;
            return;
          }

          this.template.footer.text = "O código expira em {{1}} minutos.";
        }
      },
      deep: true
    }
  },
  async beforeMount() {
    const response = await this.$http.post(`/v1/numeros/list`, { all: true });
    this.numeros = response.data.list;

    const id = this.$route.params.id ? this.$route.params.id.match(/^[0-9a-fA-F]{24}$/)[0] : "";
    if (id) {
      this.telaEdicao = true;
      await this.start(id);
    }
  }
};
</script>
